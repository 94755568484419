import DashboardLayout from 'layouts/Dashboard';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AppRoutes from 'routes';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<DashboardLayout />}>
          {AppRoutes.map((route, index) => (
            <Route key={index} path={route.path} element={route.component} />
          ))}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
