import React, { Fragment } from 'react'
import Logo from 'assets/images/logo.png'
import avatar3 from 'assets/images/avatars/3.png'
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <Fragment>
      <div class="header-margin"></div>
      <header data-add-bg="" class="header -dashboard bg-white js-header" data-x="header" data-x-toggle="is-menu-opened">
        <div class="header__container px-30 sm:px-20">
          <div class="-left-side">
            <Link to="/" class="header-logo" data-x="header-logo" data-x-toggle="is-logo-dark">
              <img src={Logo} className="ml-50" style={{ width: 45, height: 45 }} alt="" />
            </Link>
          </div>
        </div>
      </header>
    </Fragment>
  )
}

export default Header;