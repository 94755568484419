import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { LazyLoading } from 'components/Loaders';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from './Header';
import SideNav from './SideNav';

function DashboardLayout() {

  return (
    <Suspense fallback={<LazyLoading />}>
      {/* ========== Header ========== */}
      <Header />
      <div>
        {/* ========== Main ========== */}
        <ToastContainer/>
        <Outlet />
      </div>
    </Suspense>
  )
}

export default DashboardLayout