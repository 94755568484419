import { lazy } from "react";

const DeleteAccount = lazy(() => import("pages/DeleteAccount"));
const Login = lazy(() => import("pages/Login"));

const AppRoutes = [
  {
    path: "/delete-account",
    component: <DeleteAccount />,
  },
  {
    path: "/login",
    component: <Login />,
  },
]

export default AppRoutes;